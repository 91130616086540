<template>
	<div class="login-container">
		<span class="page-description"
			>비밀번호 재설정을 위해<br />
			새로운 비밀번호를 입력해주세요.
		</span>
		<form class="form">
			<!-- 비밀번호 -->
			<div class="form__group" id="input-margin">
				<label for="input-2">* 새 비밀번호</label>
				<input
					v-show="toggleOn"
					id="input-2"
					v-model="form.userPw"
					placeholder="비밀번호(필수)"
					maxlength="20"
					type="password"
				/>
				<input
					v-show="!toggleOn"
					id="input-2"
					v-model="form.userPw"
					placeholder="비밀번호(필수)"
					maxlength="20"
					type="text"
				/>
				<button class="btn__show-pw" @click="toggleClass">
					<i v-show="toggleOn" class="fa fa-eye fa-md show-pw"></i>
					<i v-show="!toggleOn" class="fa fa-eye-slash fa-md show-pw"></i>
				</button>

				<!-- 유효성 검사 알림 -->
				<div v-show="pwState == false" class="validateWarning">
					비밀번호 형식을 확인해주세요.
				</div>
				<div v-show="pwState == true" class="validateSucccess">
					사용 가능한 비밀번호 입니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					10~20자 영문자, 숫자, 특수문자(!@#$%^&*?)모두 포함
				</div>
			</div>

			<!--비밀번호 확인-->
			<div class="form__group" id="input-margin">
				<label for="input-3">* 새 비밀번호 확인</label>
				<input
					v-show="toggleOn2"
					id="input-3"
					v-model="form.pwCheck"
					placeholder="비밀번호 확인(필수)"
					maxlength="20"
					type="password"
				/>
				<input
					v-show="!toggleOn2"
					id="input-3"
					v-model="form.pwCheck"
					placeholder="비밀번호 확인(필수)"
					maxlength="20"
					type="text"
				/>
				<button class="btn__show-pw" @click="toggleClass2">
					<i v-show="toggleOn2" class="fa fa-eye fa-md show-pw"></i>
					<i v-show="!toggleOn2" class="fa fa-eye-slash fa-md show-pw"></i>
				</button>

				<!-- 유효성 검사 알림 -->
				<div v-show="pwCheck == false" class="validateWarning">
					비밀번호가 일치하지 않습니다.
				</div>
				<div v-show="pwCheck == true" class="validateSucccess">
					비밀번호가 일치합니다.
				</div>

				<!-- 설명 -->
				<div class="form-guide">
					10~20자 영문자, 숫자, 특수문자(!@#$%^&*?)모두 포함
				</div>
			</div>

			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !allCheck }"
				:disabled="!allCheck"
				@click="submitForm()"
			>
				비밀번호 재설정
			</button>
		</form>
	</div>
</template>

<script>
import { validatePw } from '@/utils/validation';
// import findpwService from '../../services/findpwService';

export default {
	data() {
		return {
			form: {
				userPw: '',
				pwCheck: '',
				hash: this.$route.params.hash,
			},
			toggleOn: true,
			toggleOn2: true,
		};
	},
	mounted() {
		console.log(this.$route);
	},
	computed: {
		pwState() {
			if (this.form.userPw != '') {
				return validatePw(this.form.userPw);
			} else {
				return null;
			}
		},
		pwCheck() {
			if (this.form.pwCheck.length == '') {
				return null;
			} else {
				if (this.form.pwCheck === this.form.userPw) {
					return true;
				} else {
					return false;
				}
			}
		},
		allCheck() {
			if (this.pwState == true && this.pwCheck) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		toggleClass() {
			this.toggleOn = !this.toggleOn;
		},
		toggleClass2() {
			this.toggleOn2 = !this.toggleOn2;
		},
		submitForm() {
			// const userData = {
			// 	userPW: this.form.userPw,
			// 	pwCheck: this.form.pwCheck,
			// };

			// findpwService
			// 	.changepw(userData)
			// 	.then(res => {
			// 		console.log('Success insert data');
			// 		console.log(res.data);
			// 	})
			// 	.catch(err => {
			// 		console.log(err);
			// 		alert('비밀번호 변경 폼 제출');
			// 	});

			console.log('비밀번호 변경 폼 제출');
			// 비밀번호 변경 완료 페이지로 이동
			this.$router.push('/modifypw/complete');
		},
	},
};
</script>

<style></style>
